import React, { useEffect, useState } from "react";
import "../css/home.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, getNotification } from "../../Redux/Action/action";
import FooterOthers from "./FooterOthers";
import Loader from "react-js-loader";
import { tabItem } from "../Agreement_Data/tabItem";
import customizedTemplate from "../../assets/customizedTemplate.png"
import customizedTemplateBG from "../../assets/customizedTemplateBG.png"
import falcon from "../../assets/falcon.png"
import owl from "../../assets/owl.png"
import wave from "../../assets/wave.png";
import welcomeBG from "../../assets/welcomeBG.png";
import Progress from "./Progress";
import Popup from "./Popup";


const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;
const Home = () => {
  const state = useSelector((state) => state.stateReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const [descriptionValue, setDescriptionValue] = useState(false);
  const [loader, setLoader] = useState(false);

  const [tabItemState, setTabItemState] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [popup, setPopup] = useState(false)

  const [progress, setProgress] = useState([
    {
      name: "Figures and Drawings",
      icon: "F",
      lastDate: "",
      lastTime: "",
      done: false,
    },
    {
      name: "Claims Playground",
      icon: "C",
      lastDate: "",
      lastTime: "",
      done: false,
    },
    {
      name: "Title and Technical Field",
      icon: "T",
      lastDate: "",
      lastTime: "",
      done: false,
    },
    {
      name: "Background",
      icon: "B",
      lastDate: "",
      lastTime: "",
      done: false,
    },
    {
      name: "Summary",
      icon: "S",
      lastDate: "",
      lastTime: "",
      done: false,
    },
    {
      name: "Abstract",
      icon: "A",
      lastDate: "",
      lastTime: "",
      done: false,
    },
    {
      icon: "D",
      name: "Drawings and Detailed Description of Embodiments",
      lastDate: "",
      lastTime: "",
      done: false,
    },
    {
      name: "Download complete Application",
      lastDate: "",
      lastTime: "",
      done: false,
    },
  ])

  useEffect(() => {
    setTabItemState(tabItem)
    storeTheProgressData()
    // if (UserService.isLoggedIn()) getPlanDetails()
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const storeTheProgressData = () => {
    const masterProgressData = JSON.parse(localStorage.getItem(UserService.getUserId() + "Progress"));
    if (masterProgressData) {
      setProgress([...masterProgressData]);
    } else {
      localStorage.setItem(UserService.getUserId() + "Progress", JSON.stringify([...progress]))
    }
  };


  const handleSearch = () => {
    if (searchQuery === "") setTabItemState(tabItem);
    else {
      // Filter tabItemState based on the searchQuery
      const filteredItems = tabItemState.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Update the state with the filtered items
      setTabItemState(filteredItems);
      dispatch(
        getNotification({
          message: `${filteredItems.length} result${filteredItems.length === 0 ? "" : "s"} found.`,
          type: "default",
        })
      )
    }
  };
  const handleBackSpace = (e) => {
    if ((e.key === "Enter" || e.key === "Backspace") && searchQuery === "") setTabItemState(tabItem);
  }

  const handleKeyPress = (e) => { if (e.key === 'Enter') handleSearch(); };

  const handleReset = () => {
    // Reset the state to its original value
    setTabItemState(tabItem);
    setSearchQuery('');
  };

  return (
    <div className="flex items-center justify-center flex-col min-h-screen bg-[#F9F9F9]">
      {
        loader ?
          <div className="m-8 my-20 max-w-[400px] mx-auto">
            <div className="mb-8">
              <div style={{ marginTop: "0vh" }} className="md:col-span-2 md:mt-0">
                <Loader
                  type="bubble-loop"
                  bgColor={"#000000"}
                  title={"Loading...."}
                  color={"#000000"}
                  size={100}
                />
              </div>
            </div>
          </div>
          :
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-sm-9">
                <div className="lg:w-full mb-3">
                  <div className="row rounded-3xl h-full bg-white flex-row justify-between p-3">
                    <div className="col-sm">
                      <h2 className="text-gray-900 text-lg title-font font-medium flex">Welcome to the MyPatentWriter App!
                        <img className="mx-3" src={wave} alt="wave" />
                      </h2>
                      <p className="leading-relaxed text-base">Scripters for drafting Patent Specifications.
                      </p>
                    </div>
                    <div className="col-sm flex-shrink-0 flex inline-flex items-center justify-content-end space-x-4">
                      <a target="_blank" href="https://www.notion.so/How-to-use-Scripters-1dd64a81cf4041dbb3322789b083d3ca" className="btn btn-outline-primary">Guide</a>
                      <button onClick={() => setPopup(true)} className="btn btn-outline-primary">Reset Application</button>
                    </div>
                    {popup && <Popup onConfirm={() => {
                      // Clear local storage
                      localStorage.clear();
                      // Reload the page
                      setPopup(false)
                      window.location.reload();
                    }} onCancel={() => setPopup(false)} text={"This will erase all inputs and current sessions for all Scripters. Are you sure you want to proceed?"} proceed={true} />}
                  </div>
                </div>
                {tabItemState &&
                  tabItemState.map((ele, ind) => (
                    <div key={ind} className="container-fluid">
                      <div className="lg:w-full mb-3">
                        <div className="flex items-center justify-center rounded-3xl h-full bg-white border-gray-300 shadow-sm row flex-row">
                          <div className="col-sm-3 rounded-l-3xl bg-[#F9FAFF] py-4">
                            <h2 className="text-gray-900 text-lg title-font font-medium">{ele.name}</h2>
                            {/* <span className="bg-[#E8ECFE] rounded-tl-3xl text-primary px-3 py-0.5 tracking-widest tracking-widest text-xs absolute left-1 top-0 rounded-br scale-down">
                              {ele.indiaflag ?
                                <img width="20" height="20" src="https://img.icons8.com/color/48/india.png" alt="india" />
                                :
                                <img width="20" height="20" src="https://img.icons8.com/dusk/64/globe--v1.png" alt="globe--v1" />}
                            </span>
                            {
                              state.pricingButtonValue &&
                              <span className={
                                UserService.isLoggedIn() ?
                                  "bg-[#E8ECFE] text-primary px-1.5 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl scale-down"
                                  :
                                  !ele.paid && !ele.disabled ? "bg-[#E8ECFE] text-primary px-1.5 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl scale-down" : ele.disabled && !ele.paid ? "bg-[#E8ECFE] text-primary px-1.5 py-1 tracking-widest text-xs absolute right-9 top-0 rounded-bl scale-down" : "d-none"}>Free</span>}
                            {
                              state.pricingButtonValue &&
                              <span className={
                                UserService.isLoggedIn() ?
                                  ele.paid ? `bg-[#E8ECFE] text-primary px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl scale-down` : "d-none"
                                  : ele.paid ? `bg-[#E8ECFE] text-primary px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl scale-down` : ele.disabled && !ele.paid ? "bg-[#E8ECFE] text-primary px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl scale-down" : "d-none"
                              }><i className="fas fa-lock text-dark"></i></span>}
                            <span className={!UserService.isLoggedIn() && ele.recentlyAdded && state.pricingButtonValue ? `bg-[#F4EBFC] text-[#CF7AE4] px-1.5 py-1 tracking-widest text-xs absolute right-20 top-0 rounded-bl scale-down` : ele.recentlyAdded && state.pricingButtonValue ? `bg-[#F4EBFC] text-[#CF7AE4] px-1.5 py-1 tracking-widest text-xs absolute right-11 top-0 rounded-bl scale-down` : ele.recentlyAdded ? "bg-[#F4EBFC] text-[#CF7AE4] px-1.5 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl scale-down" : "d-none"}>Recently Added</span> */}
                          </div>
                          <div className="col-sm-6 flex items-center">
                            <p className="mt-3 text-secondary inline-flex">{ele.description1}</p>
                          </div>
                          <div className="col-sm-3 flex items-center justify-center">
                            <button
                              onClick={() => {
                                if (!UserService.isLoggedIn() && ele.disabled) {
                                  UserService.doLogin({
                                    redirectUri: REACT_APP_REDIRECT_URI + "/app",
                                  });
                                } else if (UserService.isLoggedIn() && !descriptionValue) {
                                  if (!ele.paid) {
                                    navigate(ele.to);
                                  }
                                  else {
                                    dispatch(
                                      getNotification({
                                        message: "Subscribe to TipsyTom to get access to all features.",
                                        type: "default",
                                      })
                                    )
                                    setTimeout(() => {
                                      navigate("/app/subscription")
                                    }, 3000)
                                  }
                                }
                                else navigate(ele.to);
                              }}
                              className="mt-3 my-4 px-3 py-2 text-lg btn btn-outline-primary">Script &#x2192;</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="col-sm-3 space-y-3">
                <Progress progress={progress} />
              </div>
            </div>
            <br /><br /><br />
          </div>
      }
      <FooterOthers />
    </div >
  );
};

export default Home;