import React, { useEffect } from 'react'
import "../../components/css/landingpage.css"
import img1 from "../../assets/sittingattable.png"
import img2 from "../../assets/formSS.png"
import img3 from "../../assets/homeSS.png"
import { useDispatch } from 'react-redux'
import { getNavbar } from '../../Redux/Action/action'
import { redirect, useNavigate } from 'react-router'
import UserService from '../../services/UserService'
import FooterLanding from './FooterLanding'


import firstsectionbackground from "../../assets/firstsectionbackground.png"
import firstsectiongroup from "../../assets/firstsectiongroup.png"
import scriptListSS from "../../assets/scriptListSS.jpg"
import groupIcon from "../../assets/groupIcon.png"
import middleSS from "../../assets/middleSS.jpg"
import bussinessGroup from "../../assets/bussinessGroup.png"
import documentLibrary from "../../assets/draftReadyToGo2.png"
import forEveryone from "../../assets/forEveryone.png"
import scriptListBackground from "../../assets/scriptListBackground.png"
import scriptListBackgroundRight from "../../assets/scriptListBackgroundRight.png"
import { useLocation } from 'react-router'
import MyCarousel from './MyCarousel'


const { REACT_APP_REDIRECT_URI, REACT_APP_TIPSY_URL } = process.env;
const Pricing = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    // const state = useSelector((state) => state.stateReducer);
    // useEffect(() => {
    //     hideNav()
    //     return () => dispatch(getNavbar(true))
    // }, [])
    // const hideNav = () => {
    //     dispatch(getNavbar(false))
    // }

    return (
        <section>
            <div className='container-fluid p-5'>
                <h1 className='' style={{ fontSize: "40px" }}>Pricing and Plans</h1>

                <h2 className='mt-5 mb-3'>Boutique</h2>
                <h3 className='mt-5 mb-3 text-info'>Starts USD 200 / month / user.</h3>

                <div className="alert alert-secondary text-lg" role="alert">
                    <p>Ideal for specialists and small teams.</p>
                    <p>75,000+ words / month/ user.</p>
                    <p>Access to all new features. </p>
                    <p>Email support.</p>
                </div>

                <button onClick={() => navigate("/app/contact-us", { state: { data: location.pathname, value: "boutique" } })} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-md mb-3btn-primary text-white mt-5 font-bold py-3 px-4 text-xl flex'>
                    Sign-up for a Boutique Plan
                    <svg className='mx-3 mt-4 mt-sm-2' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                    </svg>
                </button>


                <h2 className='mt-5 mb-3'>Professional</h2>

                <h3 className='mt-5 mb-3 text-info'>Starts USD 1500 / month / team.</h3>

                <div className="alert alert-secondary text-lg" role="alert">
                    <p>Ideal for professional drafting teams. </p>
                    <p>500,000+ words / month / team.</p>
                    <p>Email and phone support.</p>
                    <p>Access to all new features.</p>
                    <p>Prompts adjustment (annual plans only).</p>
                </div>

                <button onClick={() => navigate("/app/contact-us", { state: { data: location.pathname, value: "professional" } })} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-md mb-3 btn-primary text-white mt-5 font-bold py-3 px-4 text-xl flex'>
                    Sign-up for a Professional Plan
                    <svg className='mx-3 mt-4 mt-sm-2' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                    </svg>
                </button>


                <h2 className='mt-5 mb-3'>Enterprise</h2>

                <h3 className='mt-5 mb-3 text-info'>Starts USD 5000 / month / org.</h3>

                <div className="alert alert-secondary text-lg" role="alert">
                    <p>Ideal for innovating firms and organisations.</p>
                    <p>Patent specification drafting service.</p>
                    <p>Easy email services. No software adaptation.</p>
                    <p>10+ drafts/ month.</p>
                    <p>Prompts and style adjustments.</p>
                    <p>Email and phone support.</p>
                </div>

                <button onClick={() => navigate("/app/contact-us", { state: { data: location.pathname, value: "enterprise" } })} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-md mb-3 btn-primary text-white mt-5 font-bold py-3 px-4 text-xl flex'>
                    Sign-up for Enterprise
                    <svg className='mx-3 mt-4 mt-sm-2' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                    </svg>
                </button>

            </div>
            <FooterLanding />
        </section>
    )
}

export default Pricing