import React, { useEffect } from 'react'
import "../../components/css/landingpage.css"
import img1 from "../../assets/sittingattable.png"
import img2 from "../../assets/formSS.png"
import img3 from "../../assets/homeSS.png"
import { useDispatch } from 'react-redux'
import { getNavbar } from '../../Redux/Action/action'
import { redirect, useNavigate } from 'react-router'
import UserService from '../../services/UserService'
import FooterLanding from './FooterLanding'


import firstsectionbackground from "../../assets/firstsectionbackground.png"
import firstsectiongroup from "../../assets/firstsectiongroup.png"
import scriptListSS from "../../assets/scriptListSS.jpg"
import groupIcon from "../../assets/groupIcon.png"
import middleSS from "../../assets/middleSS.jpg"
import bussinessGroup from "../../assets/bussinessGroup.png"
import documentLibrary from "../../assets/draftReadyToGo2.png"
import forEveryone from "../../assets/forEveryone.png"
import scriptListBackground from "../../assets/scriptListBackground.png"
import scriptListBackgroundRight from "../../assets/scriptListBackgroundRight.png"
import { useLocation } from 'react-router'
import MyCarousel from './MyCarousel'
import vdo from '../../assets/Demo1.mp4'


const { REACT_APP_REDIRECT_URI, REACT_APP_TIPSY_URL } = process.env;
const LandingTest = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    // const state = useSelector((state) => state.stateReducer);
    // useEffect(() => {
    //     hideNav()
    //     return () => dispatch(getNavbar(true))
    // }, [])
    // const hideNav = () => {
    //     dispatch(getNavbar(false))
    // }

    return (
        <section>
            <div className='container-fluid p-5'>
                <h2 className='mb-5 '>Cutting-edge Artificial Intelligence for Writing Patents</h2>
                <h4 className=''>Developed in collaboration with patent professionals.</h4>
                <h4 className=''>Disrupting patents work with higher quality drafting and phenomenally shorter turnaround time.</h4>
                <h2 className='mt-5 mb-3'>Benefits</h2>

                <div className="alert alert-secondary text-lg" role="alert">
                    <p>Reduce time taken per patent specification to 2-4 hours.</p>
                    <p>Streamlined workflow.</p>
                    <p>USPTO compliant.</p>
                    <p>Cutting-edge artificial intelligence.</p>
                </div>

                <h2 className='mt-5 mb-3'>Confidentiality</h2>

                <div className="alert alert-secondary text-lg" role="alert">
                    <p>Inputs and outputs not used to train or improve models.</p>
                    <p>No third party access.</p>
                    <p>Encrypted and data-blind.</p>
                    <p>Enterprise grade.</p>
                </div>


                <h2 className='mt-5'>Watch Video</h2>
                <div className="flex justify-center items-center my-5">
                    <video
                        src={vdo}
                        controls
                        autoPlay
                        loop
                        muted
                        className="w-full sm:w-3/4 rounded-xl border-4 border-gray-300 shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
                        alt="demo video"
                    >
                        Your browser does not support the video tag.
                    </video>
                </div>




                {/* <a href="https://www.notion.so/Patent-Specifications-AI-Drafting-Scripters-Walk-through-v4-0699e56765f24ea8af62c8bbf8987e51" target="_blank" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-xl mt-5 mb-3">
                    <h2 className='mt-5 mb-3 flex'>Documentation
                        <svg className='mt-3 mx-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="black" />
                        </svg>
                    </h2>
                </a>
                <button onClick={() => navigate("/pricing")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-xl mt-2 mb-3'>
                    <h2 className='flex'>Documentation */}
                {/* <svg className='mt-2 mx-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                        </svg> */}
                {/* </h2>
                </button> */}
                <div className='my-1'>
                    <button onClick={() => window.open("https://www.notion.so/Patent-Specifications-AI-Drafting-Scripters-Walk-through-v4-0699e56765f24ea8af62c8bbf8987e51", "_blank")}
                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-md mb-3'>
                        <h4 className='flex'>Documentation
                            <svg className='mt-2.5 mx-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                            </svg>
                        </h4>
                    </button>
                    <button onClick={() => navigate("/pricing")} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-xl mt-2 mb-3'>
                        <h4 className='flex'>Sign-up by choosing a suitable plan
                            <svg className='mx-3 mt-5 mt-sm-3 w-20 sm:w-8' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                            </svg>
                        </h4>
                    </button>
                </div>

            </div>
            <FooterLanding />
        </section >
    )
}

export default LandingTest