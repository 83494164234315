import React, { useState, useEffect } from 'react';
import '../css/contactus.css';
import { IoCloudUploadOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import UserService from '../../services/UserService';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getNotification } from '../../Redux/Action/action';
import Loader from "react-js-loader";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env

const ContactUS = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const receivedData = location.state?.data || null;
  const plan = location.state?.value || null;
  const [fileUploaded, setFileUploaded] = useState(false);

  // const [fileObject, setFileObject] = useState("")
  const [fileObject, setFileObject] = useState([])
  const [loader, setLoader] = useState(false)
  const [onSucess, setOnSuccess] = useState(false)

  useEffect(() => {
    // document.querySelector(".custom-footer").classNameList.add("d-none")
    // return () => {
    //   document.querySelector(".custom-footer").classNameList.remove("d-none")
    // };
  }, [])
  const handleFileUpload = (file) => {
    setFileObject(file)
    // if (file) {
    //   const reader = new FileReader();
    //   reader.readAsBinaryString(file);
    //   reader.onloadend = () => {
    //     const base64 = btoa(reader.result);
    //     setFileObject(base64);
    //   };
    // };
    setFileUploaded(true);
  }
  const resetFileUpload = () => {
    // Reset the fileUploaded state and show the upload button again
    setFileUploaded(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload(event.dataTransfer.files[0]);
  };


  const sendData = async (event) => {
    event.preventDefault();
    const name = document.getElementById("name")?.value || ""
    const phone = document.getElementById("phone")?.value || ""
    const mail = document.getElementById("mail")?.value || ""
    const designation = document.getElementById("designation")?.value || ""
    const organisation = document.getElementById("organisation")?.value || ""
    const patentNo = document.getElementById("patentNo")?.value || ""
    const seatsNo = document.getElementById("seatsNo")?.value || ""
    const size = document.getElementById("size")?.value || ""
    const query = document.getElementById("query")?.value || ""

    if (plan && name === "") {
      dispatch(
        getNotification({
          message:
            "Plesae provide your name!",
          type: "default",
        }))
    }
    else if (plan && mail === "") {
      dispatch(
        getNotification({
          message:
            "Plesae provide your email adress!",
          type: "default",
        }))
    }
    else {
      try {
        // const reader = new FileReader();
        setLoader(true);
        const FormData = require("form-data");
        const sendData = new FormData();
        // sendData.append("keycloak_user_id", UserService.getUserId());
        // sendData.append("document_name", "attached file");
        // sendData.append("document_extension", "docx");

        sendData.append("user_name", name ? name : UserService.getFullName());
        sendData.append("designation", designation);
        sendData.append("organisation", organisation);
        sendData.append("phone_number", phone);
        sendData.append("patents_number", patentNo);
        sendData.append("seats_number", seatsNo);
        sendData.append("email_id", mail);

        sendData.append("size", size);
        sendData.append("plan", plan === null ? "support" : plan);

        sendData.append("query", query);
        sendData.append("file", fileObject);

        // sendData.append("request_id", uuidv4());

        // if (phoneNo === "") setOnSuccessMessage("You don’t seem to have provided any details. No worries, our team will contact you via email.")
        // else setOnSuccessMessage("Thank you for your interest in adapting the cutting edge of legal AI. Our team will be in touch with you soon!")


        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/api/contact_us`,
          headers: {
            "Accept": "application/json",
          },
          data: sendData,
        };

        const getResponse = await axios(config);
        if (getResponse.status === 200) {
          setLoader(false);
          setOnSuccess(true)
        }
      } catch (err) {
        setLoader(false);
        console.log("Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Plesae log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/customised-template` });
          }, 3000);
        }
        else {
          dispatch(
            getNotification({
              message: "Sorry, our network is down with too many requests. Please try in sometime!",
              type: "default",
            })
          );
        }
      }
      setLoader(false);
    }
  }

  return (
    <div className="contact-popup">
      <div className="popup p-4">
        <div className="popup-content -mt-9">
          {loader ?
            <div className="m-8 my-20 max-w-[400px] mx-auto w-80">
              <div className="mb-8">
                <div style={{ marginTop: "15vh" }} className="md:col-span-2 md:mt-0">
                  <Loader
                    type="bubble-loop"
                    bgColor={"#000000"}
                    title={"Sending..."}
                    color={"#000000"}
                    size={100}
                  />
                </div>
              </div>
            </div>
            :
            onSucess ?
              <div className="m-8 my-20 max-w-[400px] mx-auto">
                <div className="mb-8">
                  <IoCheckmarkCircleOutline className="success-icon text-green-500 text-6xl mx-auto" />
                  <h1 className="mb-4 text-3xl font-extrabold">Success!</h1>
                  <p className="text-gray-600">
                  Thank you for submitting your details. You will recieve an email from our team soon.
                  </p>
                </div>
                <div className="space-y-4">
                  <button
                    onClick={() => {
                      // Reset the form and navigate to the next step or another page if needed
                      setOnSuccess(false);
                      navigate("/")
                    }}
                    className="text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl"
                  >
                    Go Home
                  </button>
                </div>
              </div>
              :
              <div className="form bg-white p-6 relative">
                {
                  plan === "boutique" &&
                  <div>
                    <div className="icon btn-primary text-white w-6 h-5 absolute flex items-center justify-center p-4" style={{ left: "-40px" }}><i className="fas fa-address-book fa-fw text-2xl"></i></div>
                    <h3 className="text-2xl text-gray-900 font-semibold">Sign-up for a Boutique Plan</h3>
                    <p className="text-gray-600">Receive details of your subscription plan over email by filling this form.</p>
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="name" placeholder="Name" className="border p-2  w-1/2" />
                      <input type="text" name="" id="designation" placeholder="Designation" className="border p-2 w-1/2" />
                    </div>
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="organisation" placeholder="Organisation" className="border p-2  w-1/2" />
                      <input type="text" name="" id="phone" placeholder="Phone" className="border p-2 w-1/2" />
                    </div>
                    <input type="text" name="" id="patentNo" placeholder="Number of patent specifications drafted by your team per month: " className="border p-2 w-full my-1" />
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="seatsNo" placeholder="Number of seats required: " className="border p-2  w-1/2" />
                      <input type="email" name="" id="mail" placeholder="Your Email" className="border p-2 w-1/2" />
                    </div>
                    <button onClick={(e) => sendData(e)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl">Submit</button>
                    <button onClick={() => navigate(receivedData)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl ml-2">Cancel</button>
                  </div>
                }
                {
                  plan === "professional" &&
                  <div className="">
                    <div className="icon btn-primary text-white w-6 h-5 absolute flex items-center justify-center p-4" style={{ left: "-40px" }}><i className="fas fa-address-book fa-fw text-2xl"></i></div>
                    <h3 className="text-2xl text-gray-900 font-semibold">Sign-up for a Professional Plan</h3>
                    <p className="text-gray-600">Receive a payment link and a subscription email by filling this form. Our team will coordinate with yours for the rest of your sign up process.</p>
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="name" placeholder="Name" className="border p-2  w-1/2" />
                      <input type="text" name="" id="organisation" placeholder="Organisation" className="border p-2  w-1/2" />
                    </div>
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="size" placeholder="Size of drafting team:" className="border p-2 w-full" />
                    </div>
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="patentNo" placeholder="Average number of patent specifications drafted by your team per month:" className="border p-2 w-full" />
                    </div>
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="phone" placeholder="Phone" className="border p-2 w-1/2" />
                      <input type="email" name="" id="mail" placeholder="Your Email" className="border p-2 w-1/2" />
                    </div>

                    <button onClick={(e) => sendData(e)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl">Submit</button>
                    <button onClick={() => navigate(receivedData)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl ml-2">Cancel</button>
                  </div>
                }
                {
                  plan === "enterprise" &&
                  <div className="">
                    <div className="icon btn-primary text-white w-6 h-5 absolute flex items-center justify-center p-4" style={{ left: "-40px" }}><i className="fas fa-address-book fa-fw text-2xl"></i></div>
                    <h3 className="text-2xl text-gray-900 font-semibold">Sign-up for Enterprise</h3>
                    <p className="text-gray-600">Share your details below. Our team will be in touch with you on the details provided below for the rest of your sign up process.</p>
                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="name" placeholder="Name" className="border p-2  w-1/2" />
                      <input type="text" name="" id="designation" placeholder="Designation" className="border p-2 w-1/2" />
                    </div>

                    <div className="flex space-x-5 mt-1">
                      <input type="text" name="" id="organisation" placeholder="Organisation" className="border p-2  w-1/2" />
                      <input type="text" name="" id="phone" placeholder="Phone" className="border p-2 w-1/2" />
                    </div>
                    <input type="email" name="" id="mail" placeholder="Your Email" className="border p-2 w-full my-2" />
                    <button onClick={(e) => sendData(e)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl">Submit</button>
                    <button onClick={() => navigate(receivedData)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl ml-2">Cancel</button>
                  </div>
                }
                {
                  plan === "" || plan === null &&
                  <div className="form bg-white p-6 relative">
                    <div className="icon btn-primary text-white w-6 h-5 absolute flex items-center justify-center p-4" style={{ left: "-40px" }}><i className="fas fa-address-book fa-fw text-2xl"></i></div>
                    <h3 className="text-2xl text-gray-900 font-semibold">Get in Touch</h3>
                    <p className="text-gray-600"> Let us know how we can assist you!</p>

                    {!fileUploaded && ( // Show the upload button if file is not uploaded
                      <div
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        className="upload2-button-container mt-3">
                        <label className="upload2-button-label">
                          <input
                            type="file"
                            accept=".docx"
                            // onChange={(event) => handleFileUpload(event.target.files[0])}
                            onChange={(event) => handleFileUpload(event.target.files[0])}
                          />
                          <IoCloudUploadOutline className="upload-icon" />
                          <p>Drag & drop a .docx file here, or click to browse</p>
                        </label>
                      </div>
                    )}
                    {fileUploaded && (
                      <div className="upload2-confirmation text-center">
                        <p className='d-flex justify-content-center mt-3' >File uploaded successfully! <IoCheckmarkCircleOutline className="upload-checkmark-icon text-success mt-1.5 mx-2" /></p>
                        <div className='mb-4'>
                          <button onClick={resetFileUpload} className="upload-reset-button p-3 bg-white border rounded w-full font-semibold">
                            Upload Another File
                          </button>
                        </div>
                      </div>
                    )}
                    <textarea name="" id="query" cols="10" rows="3" placeholder="Tell us about your query" className="border p-2 mt-3 w-full"></textarea>
                    <button onClick={(e) => sendData(e)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl">Submit</button>
                    <button onClick={() => navigate(receivedData)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl ml-2">Cancel</button>
                  </div>
                }
              </div>
          }
        </div>
      </div>
    </div >
  )
};

export default ContactUS;
